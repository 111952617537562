export const toShortAddress = (address: string, maxLength = 24) => {
  const tmpArr = address.split('.');
  const halfLength = Math.floor(maxLength / 2);
  const realAccount = tmpArr[0];
  if (realAccount.length <= maxLength) {
    return address;
  }
  return realAccount.substr(0, halfLength) + '...' + realAccount.substr(-halfLength) + (tmpArr[1] ? '.' + tmpArr[1] :  '');
}

export function isNumber(value: any) {
  const reg = /^[0-9]+\.?[0-9]*$/;
  return reg.test(value);
}

export function beautify(str = ''): string {
  const reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
  str = str.replace(reg, '$1,');
  return str.replace(/(\.[0-9]*[1-9]+)(0)*/, '$1');
}

export const toTxType = (tx: any) => {
 
  const txTypeObj: any = {
    'sell': {
      label: 'Sell Order',
      color: 'red'
    },
    'buy': {
      label: 'Buy Order',
      color: 'green'
    },
    'deposit': {
      label: 'Deposit',
      color: 'blue'
    },
    'default': {
      label: tx?.tx.type || 'Unknown',
      color: 'teal'
    }
  }

  let t = 'default';

  if (tx?.tx.type === 'cosmos-sdk/StdTx') {
    const msg = tx.tx.value.msg[0];
    if (msg?.type === 'perp/Trade') {
      if (msg?.value.buyer.timeStamp * 1 > msg?.value.seller.timeStamp * 1) {
        t = 'buy';
      } else {
        t = 'sell';
      }
    } else if (msg?.type === 'perp/Deposit') {
      t = 'deposit';
    }
  }

  return txTypeObj[t];

}