import {
  ChakraProvider
} from '@chakra-ui/react';

import { theme } from 'config';

import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Navigate 
} from 'react-router-dom';

import {
  Root,
  Home,
  Blocks,
  Txs
} from 'views';

export const App = () => (
  <ChakraProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/" element={<Root />}>
          <Route path="" element={<Navigate to="home" />} />
          <Route path="home" element={<Home />} />
          <Route path="blocks/:id" element={<Blocks />} />
          <Route path="txs/:hash" element={<Txs />} />
        </Route>
      </Routes>
    </Router>
  </ChakraProvider>
)
