import React, { useEffect } from 'react';
import useSWR from 'swr';

import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';

import {
  Container,
  Link,
  HStack,
  Text,
  Flex,
  Heading,
  List,
  VStack,
  Grid,
  IconButton,
  Skeleton,
  GridItem,
  Box
} from '@chakra-ui/react';

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Search } from 'components';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';

dayjs.extend(relativeTime);

export const Blocks: React.FC = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const { data: blockInfo } = useSWR(id ? `/blocks/${id}` : null);
  const { data: txs } = useSWR(id ? `/txs?tx.minheight=${id}&tx.maxheight=${id}&limit=1` : null);

  return (
    <Container mt={4}>
      <Box>
        <RouterLink to="/home">
          <HStack spacing={1}>
            <ChevronLeftIcon />
            <Text>Back</Text>
          </HStack>
        </RouterLink>
      </Box>
      <Flex pt={3} pb={3} borderBottomWidth={1} justifyContent="space-between" alignItems="center">
        <Skeleton isLoaded={!!blockInfo}>
          <Heading fontSize="3xl">Block #{blockInfo?.block.header.height}</Heading>
        </Skeleton>
        <Box>
          <Search />
        </Box>
      </Flex>
      <List>
        <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
          <GridItem colSpan={3}>
            <Text color="gray">Block height</Text>
          </GridItem>
          <GridItem colSpan={7}>
            <HStack>
              <IconButton aria-label="prev" size="sm" variant="ghost" onClick={e => navigate(`/blocks/${(id as any *1) - 1}`)}>
                <ChevronLeftIcon boxSize={5} />
              </IconButton>
              <Text>{blockInfo?.block.header.height || '-'}</Text>
              <IconButton aria-label="next" size="sm" variant="ghost" onClick={e => navigate(`/blocks/${(id as any *1) + 1}`)}>
                <ChevronRightIcon boxSize={5} />
              </IconButton>
            </HStack>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
          <GridItem colSpan={3}>
            <Text color="gray">Timestamp</Text>
          </GridItem>
          <GridItem colSpan={7}>
            <Skeleton isLoaded={!!blockInfo}>
              <Text>{blockInfo ? dayjs(blockInfo.block.header.time).fromNow() : '-'}</Text>
            </Skeleton>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
          <GridItem colSpan={3}>
            <Text color="gray">Transactions</Text>
          </GridItem>
          <GridItem colSpan={7}>
            <Skeleton isLoaded={!!txs}>
            {
              !txs?.txs?.length ?
              <Text>0</Text> :
              <VStack justifyContent="flex-start">
              {
                txs.txs.map((tx: any, idx: number) => (
                  <Link as={RouterLink} to={`/txs/${tx.txhash}`} key={`tx-${idx}`}>
                    <Text fontSize="sm">{tx.txhash}</Text>
                  </Link>
                ))
              }
              </VStack>
            }
            </Skeleton>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
          <GridItem colSpan={3}>
            <Text color="gray">Validated by</Text>
          </GridItem>
          <GridItem colSpan={7}>
            <Skeleton isLoaded={!!blockInfo}>
              <Link as={RouterLink} to="">
                <Text fontSize="sm">{blockInfo?.block.last_commit.signatures[0].validator_address || '-'}</Text>
              </Link>
            </Skeleton>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
          <GridItem colSpan={3}>
            <Text color="gray">Hash</Text>
          </GridItem>
          <GridItem colSpan={7}>
            <Skeleton isLoaded={!!blockInfo}>
              <Text fontSize="sm">{blockInfo?.block_id.hash || '-'}</Text>
            </Skeleton>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
          <GridItem colSpan={3}>
            <Text color="gray">Parent hash</Text>
          </GridItem>
          <GridItem colSpan={7}>
            <Skeleton isLoaded={!!blockInfo}>
              <Text fontSize="sm">{blockInfo?.block.header.last_block_id.hash || '-'}</Text>
            </Skeleton>
          </GridItem>
        </Grid>
      </List>
    </Container>
  );
}