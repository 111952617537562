import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import type { GlobalStyleProps } from '@chakra-ui/theme-tools';

const themeConfig = {
  config: {
    initialColorMode: 'light',
    colorModeManage: null,
    useSystemColorMode: false
  },

  styles: {
    global: (props: GlobalStyleProps) => ({
      body: {
        bg: mode('#f6f7fa', '#f6f7fa')(props),
        color: mode('#1b1a1c', '#1b1a1c')(props),
      }
    })
  },
  components: {
    Container: {
      baseStyle: {
        maxW: '4xl'
      }
    },
    Button: {
      baseStyle: {
        borderRadius: 'lg'
      }
    },
    Link: {
      baseStyle: {
        color: '#090fe4'
      }
    },
    Modal: {
      baseStyle: (props: GlobalStyleProps) => ({
        overlay: {
          bg: mode('blackAlpha.600', 'blackAlpha.500')(props),
        },
        dialog: {
          bg: mode('white', '#1b1a1c')(props),
        }
      })
    },
  }
}

export const theme = extendTheme(themeConfig);