import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import Decimal from 'decimal.js';
import axios from 'axios';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';

import {
  Tag,
  Container,
  VStack,
  Image,
  Box,
  Text,
  Link,
  List,
  Heading,
  Center,
  Grid,
  GridItem,
  Spinner,
  SimpleGrid
} from '@chakra-ui/react';

import { Search } from 'components';
import { DecimalUtil, toShortAddress, toTxType } from 'utils';
import { API_HOST } from 'config';
import { Link as RouterLink } from 'react-router-dom';

import logo from 'assets/logo-light.png';

type BlockRowProps = {
  block: any;
}

type TxRowProps = {
  tx: any;
}

dayjs.extend(relativeTime);

const BlockRow: React.FC<BlockRowProps> = ({ block }) => {

  return (
    <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
      <GridItem colSpan={3}>
        <Link as={RouterLink} to={`/blocks/${block.block.header.height}`}>#{block.block.header.height}</Link>
      </GridItem>
      <GridItem colSpan={2} textAlign="center">
        {dayjs().diff(dayjs(block.block.header.time), 'second')}s ago
      </GridItem>
      <GridItem colSpan={3} textAlign="center">
        {block.block.data.txs.length}
      </GridItem>
      <GridItem colSpan={2} textAlign="right">
        <Link as={RouterLink} to="">
          <Text fontSize="sm">{toShortAddress(block?.block.last_commit.signatures[0].validator_address, 12)}</Text>
        </Link>
      </GridItem>
    </Grid>
  );
}

const TxRow: React.FC<TxRowProps> = ({ tx }) => {
 
  return (
    <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
      <GridItem colSpan={3}>
        <Link as={RouterLink} to={`/txs/${tx.txhash}`}>
          {toShortAddress(tx.txhash, 16)}
        </Link>
      </GridItem>
      <GridItem colSpan={2} textAlign="center">
        {dayjs(tx.timestamp).format('HH:mm:ss')}
      </GridItem>
      <GridItem colSpan={3} textAlign="center">
        <Tag size="sm" colorScheme={toTxType(tx).color}>{toTxType(tx).label}</Tag>
      </GridItem>
      <GridItem colSpan={2} textAlign="right">
        {tx.gas_used}
      </GridItem>
    </Grid>
  );
}

export const Home: React.FC = () => {

  const { data: latestBlock } = useSWR('/blocks/latest', { refreshInterval: 5000 });
  const { data: validatorSets } = useSWR('/validatorsets/latest', { refreshInterval: 5000 });
  const { data: latestTxsData } = useSWR('/txs?tx.minheight=25&limit=5', { refreshInterval: 5000 });

  const [latestBlocks, setLatestBlocks] = useState<any[]>();
  const [latestTxs, setLatestTxs] = useState<any[]>();

  useEffect(() => {

    if (!latestBlock) {
      return;
    }

    const blockHeight = latestBlock.block.header.height;

    const promises = [];
    for (let i = blockHeight; i > blockHeight - 5; i--) {
      promises.push(axios.get(`${API_HOST}/blocks/${i}`).then(res => res.data));
    }

    Promise.all(promises).then(res => {
      setLatestBlocks(res);
    });

  }, [latestBlock]);

  useEffect(() => {

    if (!latestTxsData) {
      return;
    }

    const lastPage = latestTxsData.page_total;
    const totalCount = latestTxsData.total_count;

    const promises = [
      axios.get(`${API_HOST}/txs?tx.minheight=25&limit=5&page=${lastPage}`).then(res => res.data.txs)
    ];

    if (lastPage > 1 && totalCount % 5 !== 0) {
      promises.push(axios.get(`${API_HOST}/txs?tx.minheight=25&limit=5&page=${lastPage - 1}`).then(res => res.data.txs))
    }

    Promise.all(promises).then(res => {
      setLatestTxs(res.flat(Infinity));
    });

  }, [latestTxsData]);

  const votingPower = useMemo(() => {
    if (!validatorSets?.result.validators.length) {
      return 0;
    }
    return validatorSets.result.validators.reduce((t: number, v: any) => t + v.voting_power * 1, 0);
  }, [validatorSets]);

  return (
    <Container>
      <Container maxW="lg">
        <VStack minH="25vh" justifyContent="center" spacing={8}>
          <Box w="240px">
            <Image src={logo} w="100%" />
          </Box>
          <Search />
        </VStack>
      </Container>
      <SimpleGrid borderWidth={1} borderRadius="lg" columns={3}>
        <Box p={6} borderRightWidth={1}>
          <Text color="gray" fontSize="sm">Latest Block</Text>
          <Heading fontSize="2xl" mt={1}>{ latestBlock?.block.header.height || '-'}</Heading>
        </Box>
        <Box p={6} borderRightWidth={1}>
          <Text color="gray" fontSize="sm">Validators</Text>
          <Heading fontSize="2xl" mt={1}>
            { validatorSets?.result.validators.length || '-' }
          </Heading>
        </Box>
        <Box p={6}>
          <Text color="gray" fontSize="sm">Voting Power</Text>
          <Heading fontSize="2xl" mt={1}>
            { votingPower ? DecimalUtil.beautify(new Decimal(votingPower)) : '-' }
          </Heading>
        </Box>
      </SimpleGrid>
      <Box mt={8}>
        <Heading fontSize="lg">The latest 5 blocks</Heading>
      </Box>
      <Box mt={3}>
        {
          latestBlocks?.length ?
            <List>
              <Grid templateColumns="repeat(10, 1fr)" borderBottomWidth={1} pb={2}>
                <GridItem colSpan={3}>
                  <Text color="gray">Block</Text>
                </GridItem>
                <GridItem colSpan={2} textAlign="center">
                  <Text color="gray">Time</Text>
                </GridItem>
                <GridItem colSpan={3} textAlign="center">
                  <Text color="gray">Txns</Text>
                </GridItem>
                <GridItem colSpan={2} textAlign="right">
                  <Text color="gray">Validated</Text>
                </GridItem>
              </Grid>
              {
                latestBlocks.map((b, idx) => (
                  <BlockRow block={b} key={`block-${idx}`} />
                ))
              }
            </List> :
            <Center minH="20vh">
              <Spinner size="lg" thickness="4px" speed="1s" color="gray.500" />
            </Center>
        }
      </Box>
      <Box mt={8}>
        <Heading fontSize="lg">The latest 5 transactions</Heading>
      </Box>
      <Box mt={3}>
        {
          latestTxs?.length ?
            <List>
              <Grid templateColumns="repeat(10, 1fr)" borderBottomWidth={1} pb={2}>
                <GridItem colSpan={3}>
                  <Text color="gray">Hash</Text>
                </GridItem>
                <GridItem colSpan={2} textAlign="center">
                  <Text color="gray">Time</Text>
                </GridItem>
                <GridItem colSpan={3} textAlign="center">
                  <Text color="gray">Type</Text>
                </GridItem>
                <GridItem colSpan={2} textAlign="right">
                  <Text color="gray">Gas used</Text>
                </GridItem>
              </Grid>
              {
                latestTxs.sort((a: any, b: any) => b.height * 1 - a.height * 1).slice(0, 5).map((t: any, idx: number) => (
                  <TxRow tx={t} key={`tx-${idx}`} />
                ))
              }
            </List> :
            <Center minH="20vh">
              <Spinner size="lg" thickness="4px" speed="1s" color="gray.500" />
            </Center>
        }
      </Box>
    </Container>
  );
}