import React, { useState } from 'react';

import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Button,
  Icon,
  useBoolean
} from '@chakra-ui/react';

import axios from 'axios';
import { BiSearch } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import { API_HOST } from 'config';

export const Search: React.FC = () => {
  const navigate = useNavigate();
  const [searchKeywords, setSearchKeywords] = useState('');
  const [isSearching, setIsSearching] = useBoolean();

  const onSearch = async () => {
    if (!searchKeywords) {
      return;
    }
    setIsSearching.on();

    if (!isNaN(searchKeywords as any)) {
      const res = await axios.get(`${API_HOST}/blocks/${searchKeywords}`).catch(err => null);

      if (res) {
        navigate(`/blocks/${searchKeywords}`);
      }
    } else {
      const res = await axios.get(`${API_HOST}/txs/${searchKeywords}`).catch(err => null);

      if (res) {
        navigate(`/txs/${searchKeywords}`);
      }
      
    }
    setIsSearching.off();
  }

  return (
    <InputGroup>
      <InputLeftElement>
        <Icon as={BiSearch} boxSize={6} mt={2} ml={1} color="gray.400" />
      </InputLeftElement>
      <Input size="lg" placeholder="Search Block/Tx" bg="white" borderRadius="full" 
        onChange={e => setSearchKeywords(e.target.value)} onKeyUp={e => (e.key === 'Enter') && onSearch() } />
      <InputRightElement w="5rem" justifyContent="flex-end" alignItems="center">
        <Button size="sm" mr={2} mt={2} borderRadius="full" 
          isLoading={isSearching} isDisabled={isSearching}
          colorScheme="green" onClick={onSearch}>Search</Button>
      </InputRightElement>
    </InputGroup>
  );
}