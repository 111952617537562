import React from 'react';

import {
  Container,
  Image,
  Box,
  Flex
} from '@chakra-ui/react';

import { Link as RouterLink } from 'react-router-dom';
import logo from 'assets/logo-light.png';

export const Header: React.FC = () => {
  return (
    <Container maxW="full">
      <Flex pt={4} pb={4}>
        <RouterLink to="/">
          <Box w="150px">
            <Image src={logo} />
          </Box>
        </RouterLink>
      </Flex>
    </Container>
  );
}