import React, { useEffect } from 'react';
import useSWR from 'swr';

import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';

import {
  Container,
  Link,
  HStack,
  Text,
  Flex,
  Heading,
  List,
  Grid,
  Skeleton,
  GridItem,
  Box,
  Tag
} from '@chakra-ui/react';

import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Search } from 'components';
import { toTxType } from 'utils';
import { useParams, Link as RouterLink } from 'react-router-dom';

dayjs.extend(relativeTime);

export const Txs: React.FC = () => {

  const { hash } = useParams();

  const { data: tx } = useSWR(hash ? `/txs/${hash}` : null);

  return (
    <Container mt={4}>
      <Box>
        <RouterLink to="/home">
          <HStack spacing={1}>
            <ChevronLeftIcon />
            <Text>Back</Text>
          </HStack>
        </RouterLink>
      </Box>
      <Flex pt={3} pb={3} borderBottomWidth={1} justifyContent="space-between" alignItems="center">
        <Heading fontSize="3xl">Transaction details</Heading>
        <Box>
          <Search />
        </Box>
      </Flex>
      <List>
        <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
          <GridItem colSpan={3}>
            <Text color="gray">Tx hash</Text>
          </GridItem>
          <GridItem colSpan={7}>
            <Text fontSize="sm">{hash}</Text>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
          <GridItem colSpan={3}>
            <Text color="gray">Block</Text>
          </GridItem>
          <GridItem colSpan={7}>
            <Skeleton isLoaded={!!tx}>
              <Link as={RouterLink} to={`/blocks/${tx?.height}`}>
                <Text fontSize="sm">{tx ? '#' + tx.height : '-'}</Text>
              </Link>
            </Skeleton>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
          <GridItem colSpan={3}>
            <Text color="gray">Timestamp</Text>
          </GridItem>
          <GridItem colSpan={7}>
            <Skeleton isLoaded={!!tx}>
              <Text fontSize="sm">{tx ? dayjs(tx.timestamp).format('YYYY-MM-DD HH:mm:ss') : '-'}</Text>
            </Skeleton>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
          <GridItem colSpan={3}>
            <Text color="gray">Gas used</Text>
          </GridItem>
          <GridItem colSpan={7}>
            <Skeleton isLoaded={!!tx}>
            <Text fontSize="sm">{tx?.gas_used || '-'}</Text>
            </Skeleton>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
          <GridItem colSpan={3}>
            <Text color="gray">Gas wanted</Text>
          </GridItem>
          <GridItem colSpan={7}>
            <Skeleton isLoaded={!!tx}>
            <Text fontSize="sm">{tx?.gas_wanted || '-'}</Text>
            </Skeleton>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
          <GridItem colSpan={3}>
            <Text color="gray">Log</Text>
          </GridItem>
          <GridItem colSpan={7}>
            <Skeleton isLoaded={!!tx}>
            <Box p={4} borderRadius="lg" borderWidth={1} bg="white">
              {tx?.raw_log}
            </Box>
            </Skeleton>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
          <GridItem colSpan={3}>
            <Text color="gray">Transaction type</Text>
          </GridItem>
          <GridItem colSpan={7}>
            <Skeleton isLoaded={!!tx}>
            <Tag colorScheme={toTxType(tx).color}>{toTxType(tx).label}</Tag>
            </Skeleton>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(10, 1fr)" pt={3} pb={3} borderBottomWidth={1}>
          <GridItem colSpan={3}>
            <Text color="gray">Message</Text>
          </GridItem>
          <GridItem colSpan={7}>
            <Skeleton isLoaded={!!tx}>
            <Box p={4} borderRadius="lg" borderWidth={1} bg="white">
              {JSON.stringify(tx?.tx.value.msg)}
            </Box>
            </Skeleton>
          </GridItem>
        </Grid>
      </List>
    </Container>
  );
}