import React from 'react';

import {
  Box,
  Text,
  Container,
  Center
} from '@chakra-ui/react';

export const Footer: React.FC = () => {
  return (
    <Box borderTopWidth={1}mt={12}>
      <Container pt={6} pb={6}>
        <Center>
          <Text color="gray">
            &copy; Copyright 2022 Penguin Finance
          </Text>
        </Center>
      </Container>
    </Box>
  );
}